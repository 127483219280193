.sprays-page {
  position: relative;
  height: 100%;
}

.sprays-page-header {
  background-color: $white;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.sprays-page-body {
  background-color: $white;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 100%;
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    height: auto;
    box-sizing: border-box;
    padding: 0;
  }

  .swiper-scrollbar {
    top: 20px;
    bottom: 20px;
    height: auto;
    left: 10px;
    right: auto;
  }

}

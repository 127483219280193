/*
 * Override Ionic components here
 * ----------------------------------------------------------------------------
 * Override CSS variables for global ionic modules
 */

:root {
  /** Colors **/
  --body-font-color: #1d1d1d;
  --body-background: #ffffff;

  /** Fonts **/
  --body-font-family: 'Roboto', sans-serif;
  --header-font-family: 'Roboto', sans-serif;
}



.error-modal {
  --border-radius: 20px;
  --height: 640px;
  --background: #f1f1f1;
}

.confirm-modal {
  --border-radius: 20px;
  --height: 640px;
  --background: #f1f1f1;
}


//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Breakpoints
//   3. The Grid
//   4. Base Typography
//   5. Form

// 1. Global
// ---------

$primary-color: #09a86e;
$secondary-color: #333335;
$success-color: #09a86f;
$warning-color: #e6b020;
$warning-color-darker: #cb9e11;
$alert-color: #f73030;
$gray-100: #fcfcfc;
$gray-150: #f2f2f2;
$gray-200: #eaeaea;
$gray-250: #e8e8e8;
$gray-275: #d8d8d8;
$gray-300: #aeaeb0;
$gray-400: #9e9e9e;
$gray-500: #9e9e9e;
$gray-600: #747474;
$gray-700: #5e5e5e;
$gray-750: #383c3b;
$gray-800: #333335;
$gray-900: #28282a;
$black: #1d1d1d;
$white: #ffffff;

$global-font-size: 100%;
$global-lineheight: 1.5;
$global-radius: 4px;

$body-font-family: "Roboto", sans-serif;
$body-font-color: $black;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

$scale-green: #8aad0b;
$scale-violet: #39729e;
$scale-blue: #0083e9;
$scale-pink: #dc72ab;
$scale-turquoise: #319b9b;

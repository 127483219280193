html {
  background-color: var(--ion-background-color);
  color: var(--ion-text-color);
  font-family: var(--body-font-family);

  &.md {
    font-family: var(--body-font-family);
  }
}

body {
  font-family: $body-font-family;
  line-height: $global-lineheight;
  color: $body-font-color;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

button {
  line-height: $global-lineheight;
  color: $body-font-color;
}

.color-green {
  color: $scale-green;
}

.color-violet {
  color: $scale-violet;
}

.color-blue {
  color: $scale-blue;
}

.color-pink {
  color: $scale-pink;
}

.color-turquoise {
  color: $scale-turquoise;
}

.button {
  display: inline-block;
  background: linear-gradient($gray-100, $gray-200);
  line-height: 20px;
  font-size: 18px;
  padding: 19px 20px;
  border: 1px solid $gray-250;
  border-radius: $global-radius;
  box-shadow: 1px 2px 3px rgba($black, 0.25);
  text-decoration: none;
  color: $body-font-color;
  position: relative;

  &.square {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 0;
  }

  &.disabled {
    background: $white;
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
}

.swiper {
  height: 100%;
  width: 100%;
}


ion-select {
  &.select-button {
    background: linear-gradient($gray-100, $gray-200);
    line-height: 24px;
    font-size: 18px;
    padding: 17px 20px;
    border: 1px solid $gray-250;
    border-radius: $global-radius;
    box-shadow: 1px 2px 3px rgba($black, 0.25);
    color: $body-font-color;

    &::part(placeholder) {
      color: $body-font-color;
      opacity: 1;
    }

    &::part(icon) {
      color: $primary-color;
      opacity: 1;
    }

    &.warn {
      background: linear-gradient($warning-color, $warning-color-darker);
      color: $white;
      text-shadow: 0 1px rgba($black, 0.5);

      &::part(icon) {
        color: $white;
        opacity: 1;
      }
    }
  }
}

.checkbox-button-container {
  --color: $primary-color;

  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 20px;
    --inner-padding-start: 20px;
    --min-height: 58px;
    --border-color: #{$gray-200};
    --border-radius: #{$global-radius};
    --border-style: solid;
    --border-width: 1px;
    --background: linear-gradient(#{$gray-100}, #{$gray-200});
    --inner-border-width: 0;
  }

  ion-label {
    color: $primary-color;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: $font-weight-black;
    padding-right: 10px;
  }

  ion-checkbox {
    --border-radius: 6px;
    --border-color: #e5e5e5;
    --background: #e5e5e5;
    --size: 22px
  }
}

.radio-button-container {
  ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-end: 20px;
    --inner-padding-start: 20px;
    --min-height: 68px;
    --border-color: #{$gray-200};
    --border-radius: #{$global-radius};
    --border-style: solid;
    --border-width: 1px;
    --background: linear-gradient(#{$gray-100}, #{$gray-200});
    --inner-border-width: 0;
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
  ion-label {
    font-size: 16px;
    .small {
      display: block;
      font-size: 12px;
      color: $gray-300;
    }
  }
}

